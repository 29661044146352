<template>
  <div class="about-us" oncontextmenu="return false" onselectstart="return false">
    <!--顶部-->
    <header-nav :current="current"></header-nav>
    <div class="content" id="pdfDom">
      <div class="table" :class="{'pdfheight': isPrint}">
        <h1 class="title">
          <b v-if="pageId==1">【婚姻幸福感与家庭教育环境】
            <br>
            <br>家庭报告
          </b>
          <b v-else>【婚姻幸福感与家庭教育环境】
            <br>
            <br>家庭报告
          </b>
        </h1>
        <div class="author">
          <p>杨敦雄 编制</p>
        </div>
        <!-- 量表介紹 -->
        <p class="title3 tx-l">量表介绍</p>
        <table border="1" cellspacing="1" cellpadding="0">
          <tr>
            <th>
              <p class="tips tx-idt2">
                本量表用于了解家庭幸福感水平，并换算成分值，以幸福指数的形式呈现，为进一步提供关于如何促进儿童发展的家庭教育方式提供参考方向。婚姻幸福指数通过潜移默化的方式影响着儿童的发展。该测评必须结合儿童身心发展报告和家庭管理服务系统相结合。
                </p>
            </th>
          </tr>
        </table>
        <div class="head-title">
          <p class="title3 tx-l">基本资料</p>
          <p class="title3 tx-l">家庭编号：{{baseInfo.sn}}</p>
        </div>
        <table border="1" cellspacing="1" cellpadding="0">
          <tr>
            <th width="140px">受访者姓名</th>
            <td>
              <div class="input">
                <input type="text" readonly v-model="baseInfo.name">
              </div>
            </td>
            <th width="140px">版本</th>
            <td>
              <div class="input">
                <input type="text" readonly v-model="baseInfo.edition">
              </div>
            </td>
          </tr>
          <tr>
            <th>受访者性别</th>
            <td>
              <div class="input">
                <input type="text" readonly v-model="baseInfo.sex">
              </div>
            </td>        
            <th>出生日期</th>
            <td>
              <div class="input">
                <input type="text" readonly v-model="baseInfo.birthday">
              </div>
            </td>      
          </tr>
          <tr>
            <th>教育程度</th>
            <td>
              <div class="input">
                <input type="text" readonly v-model="baseInfo.father_edu">
              </div>
            </td>
            <th>居住地区</th>
            <td >
              <div class="input">
                <input type="text" readonly :value="baseInfo.province + baseInfo.city + baseInfo.district"/>
              </div>
            </td>          
          </tr>   
        <tr></tr>
        </table>
        <p class="title3 tx-l">本量表结果</p>
        <table border="1" cellspacing="1" cellpadding="0">
          <tr>
            <th>向度</th>
            <th>百分等级</th>
            <th>结果</th>
          </tr>
          <tr>
            <td width="17%" class="tx-c">婚姻幸福感整体水平</td>
            <td width="17%">
              <p class="tx-c">{{total.percent}}</p>
            </td>
            <td v-if="total.percent>=75" class="tx-c">婚姻幸福感很强</td>
            <td v-else-if="total.percent>=50" class="tx-c">婚姻幸福感较强</td>
            <td v-else-if="total.percent>=25" class="tx-c">婚姻幸福感较弱</td>
            <td v-else-if="total.percent>=0" class="tx-c">婚姻幸福感很弱</td>
            <!-- <td v-else class="tx-c">属正常范围。</td> -->
          </tr>
        </table>
        <!-- 百分等级分数说明 -->
        <p class="title3 tx-l">百分等级分数说明</p>
        <table border="1" cellspacing="1" cellpadding="0">
          <tr>
            <th colspan="4">
              <p class="tips">1. 百分等级在75以上属家庭幸福感很强。
                <br>2. 百分等级在50-74之间属婚姻幸福感较强。
                <br>3. 百分等级在25-49之间属婚姻幸福感较弱。
                <br>4. 百分等级在25以下者属婚姻幸福感很弱。
              </p>
            </th>
          </tr>
        </table>
        <!-- 向度分析 -->
      </div>
      <div class="table" :class="{'pdfheight': isPrint}">
        <p class="title3 tx-l">向度分析</p>
        <table border="1" cellspacing="1" cellpadding="0">
          <tr>
            <th width="20%">向度</th>
            <th>向度说明</th>
            <th>原始分数</th>
            <th>百分等级</th>
          </tr>
          <tr v-for="(list, index) in cateScore" :key="index">
            <td class="gray">{{list.cate_name}}</td>
            <td>
              <p>{{list.describe}}</p>
            </td>
            <td class="tx-c">{{list.score}}</td>
            <td class="tx-c">{{list.percent}}</td>
          </tr>
        </table>
        <!-- 剖面图 -->
        <p class="title3 tx-l">婚姻幸福感侧面图</p>
        <div class="charts" style="height: 600px;" id="charts1"></div>
      </div>
      <div class="table" :class="{'pdfheight': isPrint}">
        <p class="title3 tx-l">◆ 解释与建议</p>
        <div class="tips">
          <div v-for="(item, index) in weak.suggest" :key="index" v-if="index<100">
            <p class="tx-idt2" style="line-height: 1.6;">{{item.desc}}</p>
            <br>
          </div>
        </div>
      </div>
      <div class="table" :class="{'pdfheight': isPrint}">
        <div class="tips" v-if="weak.suggest.length>=100">
          <div v-for="(item, index) in weak.suggest" :key="index" v-if="index>=100">
            <p class="tx-idt2" style="line-height: 1.6;">{{item.desc}}</p>
            <br>
          </div>
        </div>
        <div class="tips">
          <p class="">{{baseInfo.remark}}</p>
        </div>
        
      </div>
    </div>
    <button class="print" v-on:click="printPdf">打印</button>
    <!--底部-->
    <footer-nav></footer-nav>
  </div>
</template>

<script>
import HeaderNav from "@/components/HeaderNav";
import FooterNav from "@/components/FooterNav";
import echarts from "echarts";
export default {
  name: "aboutus",
  data() {
    return {
      baseInfo: {},
      cateScore: [],
      high: [],
      highest: [],
      weak: {},
      total: "",
      isPrint: false,
      pageId: ""
    };
  },
  components: {
    HeaderNav,
    FooterNav,
  
  },
  methods: {
    getData() {
      let params = {
        exam_id: this.$route.query.eid
      };
      this.$post("report/getReport", params).then(res => {
        if (res.code === 1) {
          this.baseInfo = res.data.base_info;
          this.cateScore = res.data.cate_score;
          let index = this.cateScore.length - 1;
          this.total = this.cateScore[index];
          this.weak = res.data.weak;
          this.$nextTick(() => {
            this.drawLine();
          });
          this.$nextTick(() => {
            if (this.$route.query.print) {
              this.printPdf();
            }
          });
        } else {
          this.$layer.msg(res.msg);
        }
      });
    },
    drawLine() {
      let cateScore = this.cateScore;
      let myChart = echarts.init(document.getElementById("charts1"));
      let xdata = [];
      let ydata = [];
      for (let i = 0; i < cateScore.length; i++) {
        xdata.push(cateScore[i].cate_name);
        ydata.push(cateScore[i].percent);
      }
      myChart.setOption({
        grid: {
          top: '2%',
          left: '4%',
          right: '4%',
          bottom: "8%",
          containLabel: true
        },
        xAxis: {
          type: "category",
          data: xdata,
          axisLabel: {
            textStyle: {
              fontSize: "16",
              color: '#3e333d'
            },
            interval: 0,
            formatter: function(value) {
              //x轴的文字改为竖版显示
              var str = value.split("");
              return str.join("\n");
            }
          }
        },
        yAxis: [
          {
            name: "百\n分\n等\n级",
            type: "value",
            max: 99,
            min: 0,
            splitNumber: 20,
            nameLocation: "middle",
            nameRotate: 0,
            nameGap: 30,
            nameTextStyle: {
              fontSize: 18
            },
            splitLine: {
              show: true,
              lineStyle: {
                color: ["#664c69"],
                width: 1,
                type: "dotted"
              }
            },
            splitArea: {
              show: true,
              areaStyle: {
                color: ['#a28aa0', '#a28aa0', '#b8aab7','#b8aab7', '#b8aab7', '#fff', '#fff', '#fff', '#fff', '#fff', '#fff','#fff', '#fff', '#fff', '#fff', '#fff', '#fff', '#fff', '#fff', '#fff' ]
              }
            }
          },
          {
            name: "百\n分\n等\n级",
            type: "value",
            max: 99,
            min: 0,
            splitNumber: 20,
            nameLocation: "middle",
            nameRotate: 0,
            nameGap: 30,
            nameTextStyle: {
              fontSize: 18
            },
            splitLine: {
              show: false
            }
          }
        ],
        series: [
          {
            data: ydata,
            textStyle: {
              fontSize: 16
            },
            type: "line",
            lineStyle: {
              color: "#3e333d"
            }
          }
        ]
      });
    },
    printPdf() {
      this.isPrint = true;
      setTimeout(() => {
        this.getPdf("婚姻幸福感", () => {
          this.isPrint = false;
        });
      }, 1500);
    }
  },
  mounted() {},
  created() {
    this.getData();
    this.pageId = this.$route.query.id;
  }
};
</script>

<style lang="less" scoped>
  @import url("../../assets/css/page/result");
</style>
